//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    export default {
        props: {
            info: {
                type: Object,
                default: () => {}
            },
            isImport: { // 是否是导入界面
                type: Boolean,
                default: true
            },
            isCommon: { // 是否是常用
                type: Boolean,
                default: true
            }
        },
        data () {
            return {
                status: 0,
                meetingStatus: 0
            }
        },
        computed: {
            isAddDemand () {
                return this.meetingStatus == 30
            }
        },
        created() {
            this.status = this.$route.query.status
            this.meetingStatus = this.iStorage.get('meetingStatus')
        },
        methods: {
            editInfo () {
                this.$emit('editInfo', this.info)
            },
            sendInfo () {
                if (!this.info.phoneNumber && !this.info.email) {
                    this.$iMessage.show({
                        title: '提示',
                        contentTxt: '参会人信息未完善，请至参会名单管理中完善参会人信息后发起邀约。',
                        hasLeft: false,
                        onConfirm: () => {}
                    })
                } else {
                    this.$emit('sendInfo', this.info)
                }
            },
            selectItem () {
                this.$emit('selectItem', this.info)
            }
        }
    }
