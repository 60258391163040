//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import newHead from '@/components/newHead/newHead'
    import { iScroll } from '@/icomponents';
    import personInfoCard from "./personInfoCard";
    // import addPersonInfo from './addPersonInfo.vue'
    import {miceService} from "../../service/miceService";
    import selectList from "@/components/eventList/selectList";
    import {getCookie} from "tiny-cookie";
    export default {
        components: {
            newHead,
            iScroll,
            personInfoCard,
            // addPersonInfo,
            selectList
        },
        data () {
            return {
                tipsList: [
                    '若状态为【未邀约】，则需要手工点击【发送】按钮邀约。',
                    '可临时新增邀约名单，进行临时邀约。',
                    '若参会人没收到邀约信息，请尝试邮件，或修改手机号码重新发送。',
                    '参会人的邀约信息均为个性化链接，不可与他人使用！'
                ],
                dataList: [],
                pageIndex: 1,
                pageSize: 100,
                allLoaded: true,
                topHeight: 0,
                noDataTxt: '<p>参会名单尚未完成收集或审批</p>'
            }
        },
        computed:{
            selectedLength () {
                return this.dataList.length
            }
        },
        mounted() {
            this.getScrollBoxHeight();
        },
        created() {
            this.InvitationSyncFun()
        },
        methods: {
            goBack () {
                this.$router.back()
            },
            getScrollBoxHeight() {
                this.$nextTick(() => {
                    const event_header_container = document.getElementsByClassName("event_header_container");
                    this.topHeight = event_header_container ? event_header_container[0].clientHeight + 20 : 0;
                })
            },
            async loadData () {
                let params = {
                    MettingId: this.$route.query.mettingId,
                    pageNo: this.pageIndex,
                    pageSize: this.pageSize,
                }
                let res = await miceService.invitationQuery(params)
                if (res && res.success) {
                    this.$refs.scrollWrapper.scrollListener();
                    this.dataList = res.content
                }
            },
            editInfo (item) {
                this.$refs.addPersonInfo.showPopup(item, true)
            },
            async InvitationSyncFun () {
                let params = {
                    MettingId: this.$route.query.mettingId
                }
                let res = await miceService.InvitationSync(params)
                if (res && res.success) {
                    // this.$iToast(res.content.count > 0 ? `已同步${res.content.count}位参会者，若有其他临时视频会议参会人员，请自行【手工添加】。` : '无参会人员同步，请自行【手工添加】参会人');
                    this.loadData()
                }
            },
            async sendInfo (item) {
                this.$refs.selectList.openselect(item);
            },
            openFASCO () {
                let config= {
                    development: 'https://fasco-test-sso.roche.com.cn/roche_meeting/dist/index.html#/navigatorIndex',
                    production: 'https://fasco-sso.roche.com.cn/roche_meeting/dist/index.html#/navigatorIndex'
                }
                let eventData = this.iStorage.get('eventData') || JSON.parse(getCookie("eventData"))
                this.iJsBridge.call({
                    method: "SMGeneral.openNewWebSite",
                    postData: {
                        loadUrl: `${config[process.env.NODE_ENV]}?id=${eventData.extNum}&accountname=${eventData.userName}`,
                        browserMode: {
                            navBarVisibilty: true,
                            titleTextColor: "#ffffff",
                            navBackgroundColor: "#2D82F0"
                        }
                    }
                })
            }
        }
    }
