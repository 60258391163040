//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    export default {
        props: {
            title: {
                type: String,
                default: '请选择'
            },
            tips: {
                type: String,
                default: '提示'
            },
            hasLeft: {
                type: Boolean,
                default: true
            },
            hasRight: {
                type: Boolean,
                default: false
            },
            hasTitle: {
                type: Boolean,
                default: true
            },
            hasTips: {
                type: Boolean,
                default: false
            },
            leftTxt: {
                type: String,
                default: "返回"
            },
            smHleftEvent: {
                type: Function,
                default: new Function
            },
            smHrightEvent: {
                type: Function,
                default: new Function
            },
            tipsList: {
                type: Array,
                default: () => []
            }
        },
        data () {
            return {
                showTips: false,
                tipsTop: 0
            }
        },
        mounted () {
            this.getHeadBoxHeight()
        },
        methods: {
            getHeadBoxHeight () {
                this.$nextTick(() => {
                    let headContainer = document.getElementsByClassName('headBox')
                    this.tipsTop = headContainer ? headContainer[0].clientHeight : 100
                })
            }
        }
    }
